import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Inicio.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Inicio',
        // component: Home,
        alias: '/inicio',
        component: () =>
            import(/* webpackChunkName: "inicio" */ '../views/Inicio.vue'),
    },
    {
        path: '/nosotras',
        name: 'Nosotras',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "nosotras" */ '../views/Nosotras.vue'),
    },
    {
        path: '/talleres',
        name: 'Talleres',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "talleres" */ '../views/Talleres.vue'),
    },
    {
        path: '/media',
        name: 'Galeria de Imagenes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "media" */ '../views/Media.vue'),
    },
    {
        path: '*',
        name: 'NotFound',
        component: () =>
            import(/* webpackChunkName: "404" */ '../views/NotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
