<template>
    <v-card :color="color" elevation="0" tile>
        <v-card-title>
            <span class="ml-2 font-weight-bold" :class="heading">
                Formulario de Contacto
            </span>
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="submit"
        >
            <v-card-text>
                <small class="ml-2" :class="alert"
                    >* campos marcados con un asterisco, son obligatorios</small
                >

                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :dark="theme"
                                label="Su Nombre*"
                                required
                                hint="Nombre/s"
                                :rules="nameRules"
                                v-model="name"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :dark="theme"
                                label="Su Apellido*"
                                required
                                hint="Apellido/s"
                                :rules="lastnameRules"
                                v-model="lastname"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :dark="theme"
                                label="Email*"
                                required
                                hint="ejemplo: cuentausuario@dominio.ext"
                                :rules="emailRules"
                                v-model="email"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :dark="theme"
                                label="Telefono*"
                                required
                                hint="ejemplo: 5691231234"
                                :rules="phoneRules"
                                v-model="phone"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :dark="theme"
                                v-model="level"
                                :items="[
                                    'Starlight (sala cuna mayor)',
                                    'Sunshine (medio menor)',
                                    'Rainbow (transicion)',
                                ]"
                                label="Nivel de Interes"
                                attach
                                chips
                                multiple
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                :dark="theme"
                                v-model="course"
                                :items="[
                                    'Ciencias',
                                    'Cocina',
                                    'Reciclado',
                                    'ArteManualidades',
                                    taller,
                                ]"
                                label="Talleres de Interes"
                                attach
                                chips
                                multiple
                                :value="course"
                            ></v-select>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                :dark="theme"
                                label="Mensaje"
                                hint="Haganos saber el motivo de su consulta"
                                auto-grow
                                rows="3"
                                v-model="message"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-text class="text-center">
                <v-col cols="12" v-if="$store.state.loading">
                    <v-progress-circular
                        indeterminate
                        :color="loader"
                    ></v-progress-circular>
                </v-col>
                <v-col cols="12" v-if="$store.state.alert.show">
                    <v-alert
                        prominent
                        border="left"
                        :color="$store.state.alert.style"
                        :type="$store.state.alert.type"
                        v-text="$store.state.alert.message"
                    ></v-alert>
                </v-col>
            </v-card-text>
            <vue-recaptcha
                ref="recaptcha"
                @verify="onVerify"
                :sitekey="google_captcha_site_key"
                :loadRecaptchaScript="true"
            >
            </vue-recaptcha>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    large
                    dark
                    class="mb-4 mr-4 px-4"
                    @click="validate"
                >
                    Enviar
                </v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
export default {
    props: ['taller', 'color', 'heading', 'theme', 'alert', 'loader'],
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            google_captcha_site_key: '6Ldsyt0bAAAAAHeUIs9BE1WZHAS34yPvWl0Eq5CC',
            valid: false,
            value: false,
            name: '',
            nameRules: [(v) => !!v || 'Debe ingresar su nombre'],
            lastname: '',
            lastnameRules: [(v) => !!v || 'Debe ingresar su apellido'],
            email: '',
            emailRules: [
                (v) => !!v || 'Debe ingresar su correo',
                (v) => /.+@.+\..+/.test(v) || 'Correo electronico no es valido',
            ],
            phone: '',
            phoneRules: [
                (v) => !!v || 'Debe ingresar un numero de telefono',
                (v) => (v && !isNaN(v)) || 'Solo numeros',
            ],
            level: '',
            course: [this.taller],
            message: '',
            robot: false,
        };
    },
    methods: {
        validate() {
            this.valid = this.$refs.form.validate();
            if (this.valid && this.robot) {
                this.submit();
                this.$refs.form.resetValidation();
            } else {
                this.$store.commit('alert', {
                    show: true,
                    color: 'pink',
                    type: 'warning',
                    message:
                        'Debe completar todos los campos requeridos y resolver el captcha',
                });
            }
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        submit() {
            let json = {
                name: this.name,
                lastname: this.lastname,
                phone: this.phone,
                email: this.email,
                level: this.level,
                course: this.course,
                message: this.message,
            };
            this.name = '';
            this.lastname = '';
            this.phone = '';
            this.email = '';
            this.level = '';
            this.course = '';
            this.message = '';
            this.robot = false;
            this.$store.dispatch('connect', json);
        },

        onVerify: function (response) {
            if (response) this.robot = true;
        },
    },
    watch: {
        robot() {
            this.robot;
        },
    },
};
</script>

<style lang="scss" scoped>
.talleres {
    margin-bottom: 250px;
}

$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}
.talleres-title {
    text-shadow: 0px 4px 3px $official-red;
}
.word-break {
    word-break: normal;
}
</style>
