import Axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        navigationDrawer: false,
        testing: false,
        alert: {
            show: false,
            color: '',
            type: 'info',
            message: '',
        },
        loading: false,
    },
    mutations: {
        loading(state, payload) {
            state.loading = payload;
        },
        alert(state, payload) {
            state.alert.show = payload.show;
            state.alert.color = payload.color;
            state.alert.type = payload.type;
            state.alert.message = payload.message;
        },
    },
    actions: {
        connect(context, payload) {
            this.commit('loading', true);
            Axios.post('https://api.sunnycenter.cl/contact/notify/', payload, {
                headers: {
                    'content-type': 'application/json',
                },
            })
                .then((response) => {
                    console.log(response);
                    console.log(response.status);
                    this.commit('loading', false);
                    if (response.status == 200) {
                        this.commit('alert', {
                            show: true,
                            color: 'cyan accent-1',
                            type: 'success',
                            message:
                                'Su mensaje fue enviado, pronto nos pondremos en contacto contigo',
                        });
                    } else {
                        this.commit('alert', {
                            show: true,
                            color: 'orange darken-1',
                            type: 'error',
                            message:
                                'Su mensaje no pudo ser enviado, intentalo nuevamente o escribenos por RRSS o por telefono',
                        });
                    }
                })
                .catch(() => {
                    this.commit('loading', false);
                    this.commit('alert', {
                        show: true,
                        color: 'red darken-1',
                        type: 'error',
                        message:
                            'Su mensaje no pudo ser enviado, intentalo nuevamente o escribenos por RRSS o por telefono',
                    });
                });
            setTimeout(() => {
                this.commit('alert', {
                    show: false,
                    color: '',
                    type: '',
                    message: '',
                });
            }, 10000);
        },
    },
    modules: {},
    getters: {},
});
