<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 546 314"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="cloud">
            <g id="inner-coud">
                <path
                    d="M81.8631 147.734c6.8777 0 13.2159 2.33 18.2679 6.241 5-26.34 28.133-46.264 55.913-46.264h1.152c6.888-43.2644 44.367-76.3298 89.575-76.3298 44.658 0 81.753 32.2832 89.284 74.7808 10.436-12.0257 25.83-19.6439 42.999-19.6439 31.442 0 56.929 25.4879 56.929 56.9209 0 .411-.022.814-.029 1.22 3.34-.782 6.815-1.22 10.384-1.22 25.086 0 45.423 20.345 45.423 45.424 0 25.087-20.337 45.416-45.423 45.416-9.565 0-18.434-2.958-25.758-8.01-6.099 26.489-29.813 46.242-58.153 46.242-18.455 0-34.959-8.388-45.903-21.545-10.757 15.722-28.818 26.031-49.295 26.031-24.689 0-45.882-14.981-54.969-36.356-6.961 4.429-15.229 7.002-24.098 7.002-16.328 0-30.622-8.692-38.517-21.699-5.207 12.914-17.842 22.031-32.624 22.031-19.4196 0-35.1562-15.742-35.1562-35.172 0-1.771.1242-3.525.3837-5.236h-.3837c-16.5354 0-29.9379-13.4-29.9379-29.932 0-16.534 13.4025-29.939 29.9379-29.939"
                    fill="#fff"
                />
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    props: ['width', 'height'],
};
</script>

<style lang="scss" scoped>
#cloud {
    filter: drop-shadow(0px 4px 3px #0a2a6e);
}
</style>
