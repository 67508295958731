<template>
    <div
        class="menu-floating-cloud"
        @click="$store.state.navigationDrawer = !$store.state.navigationDrawer"
    >
        <span class="text-h5 red--text text--darken-4">
            <v-icon color="official-red" small>mdi-menu</v-icon>
            menu
        </span>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
// Global Variables
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.menu-floating-cloud {
    position: fixed;
    top: 2%;
    right: 1%;
    background: transparent url('../../assets/cloud.svg') center center
        no-repeat;
    background-size: cover;
    width: 160px;
    height: 80px;
    text-align: center;
    line-height: 80px;
    cursor: pointer;
    font-size: 2.25rem;
    z-index: 999;
}
</style>
