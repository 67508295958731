<template>
    <section>
        <!-- Title -->
        <v-container>
            <v-row
                align="center"
                justify="center"
                :class="{
                    'mt-n16': $vuetify.breakpoint.xs,
                    'mt-n6': $vuetify.breakpoint.smAndUp,
                }"
            >
                <v-col cols="12" class="mb-12">
                    <h1
                        id="home-title"
                        class="
                            text-h2 text-center text-uppercase
                            font-weight-bold
                            home-title
                        "
                        :class="{ 'text-h3': $vuetify.breakpoint.sm }"
                    >
                        Jardin infantil y Sala Cuna
                    </h1>
                </v-col>
            </v-row>
        </v-container>
        <!-- Cards -->
        <v-container>
            <v-row align="start" justify="center" class="mt-12">
                <v-col cols="12" sm="6" lg="5">
                    <v-card
                        color="white"
                        rounded="lg"
                        elevation="4"
                        class="pa-4"
                        id="card-one"
                    >
                        <v-card-title
                            class="
                                text-uppercase text-h4
                                font-weight-bold
                                deep-purple--text
                            "
                            :class="{
                                'text-h5':
                                    $vuetify.breakpoint.sm ||
                                    $vuetify.breakpoint.lg,
                            }"
                            >Plan Vuelta Segura</v-card-title
                        >
                        <v-card-text class="deep-purple--text text-h6"
                            >Sabemos lo importante que es la seguridad y bienestar de nuestros niños y niñas, por eso contamos con protocolo Covid-19 aprobado por Ministerio de Educación y Seremi de Salud.</v-card-text
                        >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                x-large
                                color="deep-purple"
                                class="text-end"
                                @click="showCovidContent = !showCovidContent"
                            >
                                ver m&aacute;s
                                <v-icon medium>
                                    {{
                                        showCovidContent
                                            ? 'mdi-chevron-up'
                                            : 'mdi-chevron-down'
                                    }}
                                </v-icon>
                            </v-btn>
                        </v-card-actions>
                        <v-expand-transition>
                            <div v-show="showCovidContent">
                                <v-divider></v-divider>

                                <v-card-text
                                    v-for="(item, i) in protocolo"
                                    :key="i"
                                >
                                    <v-row align="start" justify="center">
                                        <v-col cols="1">
                                            <v-icon color="deep-purple">
                                                mdi-arrow-right-circle-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col cols="11">
                                            <p>{{ item.text }}</p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-text>
                                    <v-row align="start" justify="center">
                                        <v-col cols="12">
                                            <p>
                                                Frente a un caso positivo
                                                nuestro proceder será avisar de
                                                manera inmediata a los
                                                apoderados del nivel afectado
                                                y/o jardín Infantil según sea la
                                                causante.
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </div>
                        </v-expand-transition>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" lg="5">
                    <v-card
                        color="white"
                        rounded="lg"
                        elevation="4"
                        class="pa-4"
                        id="card-two"
                    >
                        <v-card-title
                            class="
                                text-uppercase text-h4
                                font-weight-bold
                                official-red--text
                            "
                            :class="{
                                'text-h5':
                                    $vuetify.breakpoint.sm ||
                                    $vuetify.breakpoint.lg,
                            }"
                            >Summer School</v-card-title
                        >
                        <v-card-text class="official-red--text text-h6"
                            >Las clases se termina en diciembre, pero la diversión continua en enero con nuestro Summer School, una instancia para seguir divirtiéndonos con entretenidas actividades y talleres.</v-card-text
                        >
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                x-large
                                color="official-red"
                                class="text-end"
                                to="/talleres"
                            >
                                ver m&aacute;s
                                <v-icon medium> mdi-chevron-right </v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- Red Airplane -->
        <v-container fluid class="mt-10">
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <red-plain-with-clouds />
                </v-col>
            </v-row>
        </v-container>
        <!-- Levels -->
        <section-levels />
    </section>
</template>

<script>
import RedPlainWithClouds from '../components/RedPlainWithClouds.vue';

import SectionLevels from '../components/SectionLevels.vue';

export default {
    name: 'Home',
    components: {
        RedPlainWithClouds,
        SectionLevels,
    },
    data: () => ({
        showCovidContent: false,
        protocolo: [
            {
                text: 'Demarcación al ingreso del Jardín Infantil',
            },
            {
                text: 'Recorrido con señaléticas, y demarcación hasta el Hall en el cual se tomarán la temperatura de adultos y niños',
            },
            {
                text: 'Limpieza de calzado de los niños al ingresar al establecimiento',
            },
            {
                text: 'Basurero de limpieza para botar la mascarilla desechable, e ingresar al establecimiento.',
            },
            {
                text: 'Lavado de manos antes de ingresar a su nivel',
            },
            {
                text: 'Salas demarcadas respetando la distancia de mesas (1metro)',
            },
            {
                text: 'Material sanitizado al término de cada jornada',
            },
            {
                text: 'Cada Sala cuenta con lavamanos, totalmente equipadas con material de higiene',
            },
            {
                text: 'Demarcación en baños (WC, lava manos)',
            },
            {
                text: 'Juegos de patio sanitizados al finalizar cada jornada',
            },
            {
                text: 'Cada nivel tiene su rutina y organización del tiempo diferenciada de cada nivel',
            },
            {
                text: 'Aforo reducido de cantidad de niños por cada nivel',
            },
            {
                text: 'Cada apoderado envía la colación desde el hogar (se envía minuta a sugerencia)',
            },
        ],
    }),
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}
#home-title {
    animation-name: titles;
    animation-duration: 6s;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
.home-title {
    text-shadow: 0px 4px 3px $official-red;
}
@keyframes titles {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(10px);
    }
}

#card-one,
#card-two,
#card-three {
    animation-name: cards;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
#card-one {
    animation-duration: 6s;
}
#card-two {
    animation-duration: 5s;
}
#card-three {
    animation-duration: 5.5s;
}

@keyframes cards {
    from {
        transform: translateY(-5px);
    }
    to {
        transform: translateY(5px);
    }
}
</style>
