<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 1300 1100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="red-plane-kid">
            <path
                id="path14"
                d="M1044.59 584.312l-12.62 4.245c-7.61-22.897-10.35-42.05-8.19-57.838.65-4.914 1.8-9.504 3.43-13.772 1.45-3.878 3.3-7.487 5.55-10.852l11.06 7.442-.07.112c-2.74 4.111-4.7 8.868-5.9 14.262-3.2 14.394-.95 33.281 6.74 56.401z"
                fill="#FF8080"
            />
            <path
                id="path16"
                d="M1046.18 584.407c0 7.401-5.97 13.401-13.34 13.401s-13.34-6-13.34-13.401c0-7.402 5.97-13.402 13.34-13.402s13.34 6 13.34 13.402z"
                fill="#FF8080"
            />
            <path
                id="path18"
                d="M1007.92 558.723c22.91 4.605 21.56 65.749-1.01 60.726"
                stroke="#552A2D"
                stroke-width="27.0412"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path20"
                d="M1005.81 558.723c-25.345 2.977-17.251 65.749 1.01 59.824"
                stroke="#552A2D"
                stroke-width="27.0412"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path22"
                d="M998.409 559.693c-7.714 0-13.532 13.342-13.532 31.035 0 17.692 5.818 31.035 13.532 31.035 7.711 0 13.531-13.343 13.531-31.035 0-17.693-5.82-31.035-13.531-31.035z"
                fill="#2B1E23"
            />
            <path
                id="path24"
                d="M993.069 584.312l-12.62 4.245c-6.022-18.072-8.994-33.816-8.917-47.41.011-4.702.411-9.136 1.176-13.326 1.452-8.166 4.304-15.386 8.528-21.726l11.057 7.442c-2.25 3.376-3.992 7.176-5.201 11.41-1.187 4.101-1.885 8.613-2.096 13.504-.567 12.69 2.129 28.012 8.073 45.861z"
                fill="#FF8080"
            />
            <path
                id="path26"
                d="M996.371 588.449c0 5.178-1.223 9.722-3.066 12.27-1.349.47-2.806.724-4.308.724-.71 0-1.404-.055-2.078-.164-6.384-1.002-11.268-6.55-11.268-13.235 0-7.039 5.405-12.812 12.285-13.353.351-.028.701-.047 1.061-.047 1.142 0 2.256.145 3.318.425h.009c2.373 2.142 4.047 7.327 4.047 13.38z"
                fill="#FF8080"
            />
            <path
                id="path28"
                d="M987.092 524.947c-1.187 4.101-1.885 8.613-2.096 13.504l-13.464 2.696c.011-4.702.411-9.136 1.176-13.326l14.384-2.874z"
                fill="#500"
            />
            <path
                id="path30"
                d="M1043.75 513.649c-2.74 4.111-4.7 8.868-5.9 14.262l-14.07 2.808c.65-4.914 1.8-9.504 3.43-13.772l16.54-3.298z"
                fill="#500"
            />
            <path
                id="path32"
                d="M1192.58 477.364c-51.81 16.78-277.369 66.777-352.209 78.977-62.895 10.236-61.298-46.173-61.298-77.173 0-2.777.122-5.569.327-8.347.02-.174.02-.334.04-.508.675-7.91 2.187-15.704 3.921-22.8 6.902-27.686 1.796-33.22 68.815-31.852 59.587 1.192 103.963 4.943 212.414 2.848 79.76-1.541 124.34 16.31 135.02 37.787 4.98 10.061 2.5 17.985-7.03 21.068z"
                fill="#A00"
            />
            <path
                id="path34"
                d="M779.436 470.311c2.683 27.721 20.569 51.053 77.987 41.697 21.66-3.528 54.752-8.204 92.062-13.375 18.772-2.598 38.606-5.346 58.575-8.137 4.56-.629 9.15-1.271 13.71-1.913 19.82-2.778 39.58-5.595 58.34-8.371 50.3-7.412 93.58-14.484 112.47-19.735 3.09-.848 5.41-2.297 7.03-4.185-10.68-21.471-41.55-48.4-134.81-48.4-19.09 0-36.14-.177-51.63 0-.2.015-.37.015-.56.015-25.253.328-46.339.45-65.166.437h-.572c-5.321-.013-10.441-.028-15.463-.041h-.097c-14.373-.083-41.94-.247-66.484-.466-16.117-.122-30.913-.286-39.816-.464-3.595-.082-34.753 12.459-41.641 40.139-1.743 7.097-3.267 14.88-3.935 22.799z"
                fill="#D40000"
            />
            <path
                id="path36"
                d="M956.349 408.344l66.411 80.237c20.09-2.776 40.14-5.593 59.17-8.369l-59.82-72.32-65.761.452z"
                fill="#FACD08"
            />
            <path
                id="path38"
                d="M873.783 407.837l75.702 90.796c19.03-2.598 39.134-5.346 59.375-8.137l-68.543-82.193h-.096c-14.365-.082-41.916-.247-66.438-.466z"
                fill="#FACD08"
            />
            <path
                id="path40"
                d="M261.405 584.312l12.622 4.245c7.617-22.897 10.357-42.05 8.194-57.838-.653-4.914-1.797-9.504-3.426-13.772-1.454-3.878-3.306-7.487-5.556-10.852l-11.058 7.442.067.112c2.739 4.111 4.703 8.868 5.899 14.262 3.206 14.394.954 33.281-6.742 56.401z"
                fill="#FF8080"
            />
            <path
                id="path42"
                d="M259.823 584.407c0 7.401 5.972 13.401 13.338 13.401 7.367 0 13.34-6 13.34-13.401 0-7.402-5.973-13.402-13.34-13.402-7.366 0-13.338 6-13.338 13.402z"
                fill="#FF8080"
            />
            <path
                id="path44"
                d="M298.084 558.723c-22.908 4.605-21.559 65.749 1.005 60.726"
                stroke="#552A2D"
                stroke-width="27.0412"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path46"
                d="M300.189 558.723c25.346 2.977 17.252 65.749-1.005 59.824"
                stroke="#552A2D"
                stroke-width="27.0412"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path48"
                d="M307.592 559.693c7.713 0 13.532 13.342 13.532 31.035 0 17.692-5.819 31.035-13.532 31.035-7.715 0-13.532-13.343-13.532-31.035 0-17.693 5.817-31.035 13.532-31.035z"
                fill="#2B1E23"
            />
            <path
                id="path50"
                d="M312.931 584.312l12.621 4.245c6.023-18.072 8.993-33.816 8.917-47.41-.012-4.702-.41-9.136-1.176-13.326-1.453-8.166-4.304-15.386-8.529-21.726l-11.057 7.442c2.252 3.376 3.992 7.176 5.202 11.41 1.186 4.101 1.886 8.613 2.096 13.504.566 12.69-2.13 28.012-8.074 45.861z"
                fill="#FF8080"
            />
            <path
                id="path52"
                d="M309.631 588.449c0 5.178 1.222 9.722 3.065 12.27 1.349.47 2.807.724 4.308.724.711 0 1.403-.055 2.077-.164 6.386-1.002 11.268-6.55 11.268-13.235 0-7.039-5.405-12.812-12.284-13.353-.35-.028-.702-.047-1.061-.047-1.143 0-2.256.145-3.319.425h-.008c-2.374 2.142-4.046 7.327-4.046 13.38z"
                fill="#FF8080"
            />
            <path
                id="path54"
                d="M318.909 524.947c1.186 4.101 1.886 8.613 2.096 13.504l13.464 2.696c-.012-4.702-.41-9.136-1.176-13.326l-14.384-2.874z"
                fill="#500"
            />
            <path
                id="path56"
                d="M262.248 513.649c2.739 4.111 4.703 8.868 5.899 14.262l14.074 2.808c-.653-4.914-1.797-9.504-3.426-13.772l-16.547-3.298z"
                fill="#500"
            />
            <path
                id="path58"
                d="M113.418 477.364c51.811 16.78 277.371 66.777 352.211 78.977 62.895 10.236 61.298-46.173 61.298-77.173 0-2.777-.122-5.569-.326-8.347-.02-.174-.02-.334-.04-.508-.674-7.91-2.186-15.704-3.92-22.8-6.904-27.686-1.797-33.22-68.816-31.852-59.586 1.192-103.961 4.943-212.414 2.848-79.763-1.541-124.337 16.31-135.018 37.787-4.982 10.061-2.499 17.985 7.025 21.068z"
                fill="#A00"
            />
            <path
                id="path60"
                d="M526.564 470.311c-2.681 27.721-20.568 51.053-77.987 41.697-21.657-3.528-54.75-8.204-92.062-13.375-18.771-2.598-38.606-5.346-58.576-8.137-4.56-.629-9.147-1.271-13.707-1.913-19.82-2.778-39.587-5.595-58.344-8.371-50.299-7.412-93.574-14.484-112.469-19.735-3.09-.848-5.404-2.297-7.024-4.185 10.673-21.471 41.541-48.4 134.806-48.4 19.09 0 36.143-.177 51.634 0 .19.015.366.015.558.015 25.251.328 46.338.45 65.164.437h.572c5.322-.013 10.44-.028 15.463-.041h.096c14.375-.083 41.94-.247 66.484-.466 16.117-.122 30.913-.286 39.817-.464 3.592-.082 34.754 12.459 41.642 40.139 1.741 7.097 3.266 14.88 3.933 22.799z"
                fill="#D40000"
            />
            <path
                id="path62"
                d="M283.885 407.892l-59.814 72.32c19.032 2.776 39.074 5.593 59.169 8.369l66.412-80.237-65.767-.452z"
                fill="#FACD08"
            />
            <path
                id="path64"
                d="M432.217 407.837l-75.702 90.796c-19.028-2.598-39.134-5.346-59.378-8.137l68.547-82.193h.096c14.365-.082 41.915-.247 66.437-.466z"
                fill="#FACD08"
            />
            <path
                id="path66"
                d="M746.335 349.451c0 31.782-44.195 57.553-98.7 57.553-54.518 0-98.712-25.771-98.712-57.553 0-31.783 44.194-57.543 98.712-57.543 12.034 0 23.581 1.252 34.249 3.553 19.265 4.158 35.707 11.724 47.153 21.435 10.912 9.264 17.298 20.473 17.298 32.555z"
                fill="#D40000"
            />
            <path
                id="path68"
                d="M729.037 316.896c-8.22 5.04-14.649 17.925-14.649 33.471l-5.563 11.176-20.756-15.646-6.185-50.436c19.265 4.158 35.707 11.724 47.153 21.435z"
                fill="#A00"
            />
            <path
                id="path70"
                d="M656.648 201.637c43.208-32.465 74.549 8.474 78.544 71.54-55.219-3.732-79.587-14.972-78.544-71.54z"
                fill="#912127"
            />
            <path
                id="path72"
                d="M840.371 212.605c.814-1.052 1.514-2.216 2.08-3.485l7.502-16.84c3.81-8.555-.729-16.888-9.284-21.009-8.41-4.054-15.426-6.607-19.237 1.948l-9.815 22.036c-3.812 8.554.003 18.592 8.518 22.421 7.25 3.26 15.573.965 20.236-5.071z"
                fill="#F4A376"
            />
            <path
                id="path74"
                d="M690.948 304.925l49.121 3.791.018.001c1.166.088 2.309.128 3.433.13 4.105-.007 7.908-.519 11.564-1.33 5.473-1.233 10.616-3.137 15.549-5.708 2.464-1.29 4.872-2.757 7.222-4.465 2.348-1.712 4.644-3.661 6.848-6.015l.008-.008L824.9 248.3c8.827-9.449 8.323-24.265-1.127-33.092-9.45-8.828-24.265-8.324-33.092 1.125l-40.19 43.022.008-.01 1.396 1.306-1.302-1.402-.094.096 1.396 1.306-1.302-1.402-.061.052c-.163.138-.645.49-1.301.851-.983.553-2.359 1.125-3.539 1.456-.591.168-1.131.283-1.528.343-.396.062-.644.068-.644.066v1.762l.135-1.754-.135-.008v1.762l.135-1.754.017.002-49.121-3.791c-12.894-.995-24.152 8.652-25.146 21.544-.994 12.893 8.651 24.152 21.544 25.147l-.001-.002z"
                fill="#0055D4"
            />
            <path
                id="path76"
                d="M811.061 174.984l3.84 25.656"
                stroke="#F4A376"
                stroke-width="10.3456"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path78"
                d="M833.674 160.588l-13.424 26.86"
                stroke="#F4A376"
                stroke-width="10.3456"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path80"
                d="M657.72 186.331c-45.192-16.679-63.295 11.794-75.728 40.268-10.905 24.972-17.448 49.944-34.08 44.457 24.657 56.773 109.232-2.048 109.808-84.725z"
                fill="#912127"
            />
            <path
                id="path82"
                d="M594.143 416.543v-118.99c0-19.154 13.725-35.445 32.404-40.638 2.786-.78 5.68-1.307 8.656-1.56 1.356-.12 2.728-.18 4.116-.18h23.605c.661 0 1.32.016 1.976.045 2.881.115 5.695.487 8.417 1.093 19.88 4.434 34.782 21.287 34.782 41.24v118.99H594.143z"
                fill="#0055D4"
            />
            <path
                id="path88"
                d="M623.995 260.261l-22.939 59.504-15.805 41.156-32.8-12.877c6.584-32.608 28.832-92.884 71.544-87.783z"
                fill="#0055D4"
            />
            <path
                id="path94"
                d="M706.175 184.813c0 29.81-24.054 53.975-53.726 53.975-29.67 0-53.724-24.165-53.724-53.975 0-29.809 24.054-53.974 53.724-53.974 29.672 0 53.726 24.165 53.726 53.974z"
                fill="#F0755D"
            />
            <path
                id="path96"
                d="M706.175 179.873c0 29.81-24.054 53.975-53.726 53.975-29.67 0-53.724-24.165-53.724-53.975 0-29.809 24.054-53.974 53.724-53.974 29.672 0 53.726 24.165 53.726 53.974z"
                fill="#F4A376"
            />
            <path
                id="path110"
                d="M664.96 201.955c0 3.458-1.68 6.536-4.315 8.55-1.972 1.508-4.469 2.404-7.198 2.404-2.728 0-5.226-.896-7.199-2.404-2.635-2.014-4.313-5.092-4.313-8.55h23.025z"
                fill="#8C232B"
            />
            <path
                id="path112"
                d="M660.645 210.505c-1.972 1.508-4.469 2.404-7.198 2.404-2.728 0-5.226-.896-7.199-2.404 1.427-1.792 4.113-3.005 7.199-3.005 3.085 0 5.782 1.213 7.198 3.005z"
                fill="#F13F8F"
            />
            <g id="path120">
                <path
                    d="M807.791 211.965l20.133 15.028-20.133-15.028z"
                    fill="red"
                />
                <path
                    d="M807.791 211.965l20.133 15.028"
                    stroke="#D40000"
                    stroke-width="22.2992"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <path
                id="path122"
                d="M843.687 162.823l-11.202 23.508"
                stroke="#F4A376"
                stroke-width="10.3456"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path124"
                d="M849.248 169.528l-11.2 23.508"
                stroke="#F4A376"
                stroke-width="8.91968"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path126"
                d="M853.696 176.233l-7.969 16.73"
                stroke="#F4A376"
                stroke-width="7.80472"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path128"
                d="M651.099 369.94c-22.919 0-46.308-8.269-49.13-27.32l-.081-.551v-48.817c0-4.161 3.359-7.535 7.5-7.535s7.5 3.374 7.5 7.535v47.608c1.777 8.473 17.753 14.579 36.788 13.971 17.255-.554 31.291-6.72 32.2-14.094l.143-47.508c.013-4.153 3.368-7.512 7.5-7.512h.022c4.143.014 7.488 3.396 7.478 7.558l-.17 48.481c-1.361 16.227-19.689 27.271-46.696 28.135-1.016.033-2.034.049-3.054.049z"
                fill="#262528"
            />
            <path
                id="path130"
                d="M658.068 358.084h-13.231c-2.362 0-4.294-1.933-4.294-4.296v-21.211c0-2.362 1.932-4.296 4.294-4.296h13.231c2.363 0 4.295 1.934 4.295 4.296v21.211c0 2.363-1.932 4.296-4.295 4.296z"
                fill="#262528"
            />
            <path
                id="path132"
                d="M667.441 335.764c0 7.623-7.158 13.803-15.988 13.803-8.83 0-15.988-6.18-15.988-13.803 0-7.623 7.158-13.803 15.988-13.803 8.83 0 15.988 6.18 15.988 13.803z"
                fill="#262528"
            />
            <path
                id="path134"
                d="M623.784 318.052l-14.563-5.56"
                stroke="#F8A478"
                stroke-width="6.64165"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path136"
                d="M624.396 310.825l-14.047-6.225"
                stroke="#F8A478"
                stroke-width="7.19512"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path138"
                d="M622.532 302.009l-11.368-4.72"
                stroke="#F8A478"
                stroke-width="6.65992"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path140"
                d="M619.599 323.32l-11.642-2.112"
                stroke="#F8A478"
                stroke-width="6.08817"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path142"
                d="M601.409 313.613l-.997-6.088c1.173-1.192-.18-6.784-5.085-2.453l-42.304 68.712c-4.038 12.309 16.577 15.519 20.141 8.125 1.892.647 31.32-53.418 31.791-55.776l3.002-2.245c.471-2.357-4.917-9.068-6.548-10.275z"
                fill="#F8A478"
            />
            <path
                id="path144"
                d="M601.451 321.605c-2.999 1.911-3.78 6.35-2.255 7.688 2.261 1.984 5.765-1.47 8.385-4.633 3.899-4.708 2.042-7.535.276-6.575-1.613.878-4.445 2.271-6.406 3.52z"
                fill="#F8A478"
            />
            <path
                id="path146"
                d="M611.217 320.308c-.964-1.149.815-13.248 0-15.311-5.33-2.073-9.388-1.132-11.321 4.83-1.548 1.882 6.808 16.862 9.627 13.597l1.694-3.116z"
                fill="#F8A478"
            />
            <path
                id="path148"
                d="M610.349 296.58l-4.516 6.017-7.861 3.736"
                stroke="#F8A478"
                stroke-width="5.8498"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path150"
                d="M609.261 296.901l-11.709 9.576"
                stroke="#F8A478"
                stroke-width="5.29807"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path154"
                d="M610.76 296.132s-.128 14.552-.411 14.784c-.284.232-.06 11.12-.06 11.12h9.138l4.568-6.277-1.463-7.452-1.001-6.714-11.536-5.013.765-.448z"
                fill="#F8A478"
            />
            <path
                id="path164"
                d="M651.936 295.171c-5.436-.002-10.769-.392-15.795-1.138-5.034-.756-9.732-1.832-14.112-3.444-2.777-1.044-5.442-2.261-8.13-4.14l10.096-26.188c-1.718-.205-3.396-.304-5.048-.304-.138 0-.276.002-.414.003 1.519-.744 3.086-1.408 4.696-1.989l9.943-.328c-.5 1.762-.767 3.622-.767 5.545 0 11.155 9.002 20.199 20.104 20.199 11.103 0 20.103-9.043 20.103-20.199 0-2.388-.413-4.68-1.171-6.808l1.898-.063c.181.042.362.083.544.126 3.454.816 6.756 2.006 9.849 3.525 1.953.959 3.824 2.048 5.599 3.255l6.014 18.545c-1.344 1.343-2.776 2.525-4.241 3.568-2.715 1.927-5.568 3.408-8.484 4.611-5.837 2.392-11.976 3.728-18.205 4.493-4.154.503-8.344.729-12.479.731z"
                fill="#82171B"
            />
            <path
                id="path170"
                d="M613.899 286.449c-.144-.1-.29-.204-.434-.308-1.613-1.189-3.265-2.652-4.794-4.712-.811-1.097-1.578-2.372-2.2-3.836l-5.65-13.309c5.423-2.701 11.32-4.259 17.712-4.324.138-.001.276-.003.414-.003 1.652 0 3.33.099 5.048.304l-10.096 26.188z"
                fill="#82171B"
            />
            <path
                id="path172"
                d="M695.345 281.768l-6.014-18.545-1.26-3.886 4.253 1.247c2.763-.548 5.191-.791 7.44-.791 3.764 0 7.021.682 10.471 1.76l-11.698 16.452c-.981 1.379-2.056 2.628-3.192 3.763z"
                fill="#82171B"
            />
            <g id="left-scarf" fill="#F4D7D7">
                <path
                    id="path174"
                    d="M459.237 115.185c-8.02 15.106-14.517 31.006-19.321 47.431-4.265 14.576-10.64 34.655 2.576 46.523 13.563 12.18 30.777 4.012 44.256-3.268 2.996-1.619 6.037-2.688 9.317-3.579 1.41-.383 2.888-.703 4.34-.873.616-.05 1.232-.098 1.847-.147 3.301.217 2.257-1.02-3.131-3.711.603 1.862-.776 7.075-1.03 9.502-.487 4.662-.147 9.553 1.265 14.032 2.42 7.68 8.196 13.394 15.757 15.86 8.894 2.901 17.83-3.298 25.488-6.902 5.714-2.69 12.644-5.258 18.999-3.884 2.405.52 3.019 1.675 4.853 3.524 2.955 2.978 6.368 5.131 10.394 6.291 2.722.784 5.594 1.1 8.336 1.871 1.589.446 3.144 1.005 4.68 1.609-2.611-1.028.161.139.578.344 1.132.559 2.215 1.207 3.29 1.865.853.524 1.669 1.111 2.482 1.695-1.832-1.316.951.871 1.052.961 1.775 1.568 3.359 3.34 4.879 5.152 2.94 3.506 6.243 9.927 8.251 14.995.953 2.405 1.752 4.868 2.538 7.332 4.366 13.668 25.848 7.823 21.454-5.943-7.051-22.085-19.527-42.116-42.528-49.337-2.77-.869-5.626-1.436-8.472-1.977-.571-.11-1.619-1.079-3.139-2.619-3.228-3.269-6.727-5.128-10.949-6.78-7.558-2.955-16.667-1.751-24.27.256-8.154 2.153-15.125 7.061-22.977 9.775 1.421-.492 1.233 1.484.3-1.48-.996-3.159.065-7.514.439-10.734.877-7.541.301-17.368-7.396-21.458-14.842-7.89-32.736 2.553-45.863 9.388-2.191 1.14-8.688 4.068-10.557 1.157-.904-1.409-.131-4.595.146-6.176.792-4.523 1.995-8.999 3.166-13.435 4.228-16.02 9.876-32.1 17.632-46.71.801-1.507 5.37-10.547 6.212-11.735 2.562-3.617-1.07-3.384-4.822-4.471-6.288-1.82-15.698-3.16-17.148-1.954-1.317 1.094-1.906 5.692-2.924 7.61z"
                />
                <path
                    id="path176"
                    d="M469.252 85.4146c-4.655 9.92-7.847 20.2864-9.504 31.1324-.269 1.758 2.409 2.517 2.681.742 1.623-10.616 4.662-20.7424 9.226-30.4651.76-1.6173-1.638-3.0373-2.403-1.4093z"
                />
                <path
                    id="path178"
                    d="M472.64 87.4427c-5.521 11.136-8.977 22.8943-10.392 35.2573-.205 1.783 2.577 1.768 2.78 0 1.357-11.853 4.717-23.1666 10.013-33.8479.798-1.604-1.601-3.0201-2.401-1.4094z"
                />
                <path
                    id="path180"
                    d="M475.203 91.1947c-4.76 9.4103-8.062 19.3143-9.846 29.7213-.301 1.759 2.378 2.513 2.682.743 1.746-10.187 4.901-19.834 9.565-29.055.809-1.5987-1.589-3.0147-2.401-1.4093z"
                />
                <path
                    id="path182"
                    d="M473.853 111.824c2.335-6.433 4.87-12.78 7.619-19.0453.715-1.6307-1.681-3.0534-2.401-1.4107-2.847 6.4867-5.483 13.053-7.9 19.713-.614 1.692 2.073 2.422 2.682.743z"
                />
                <path
                    id="path184"
                    d="M476.217 115.943c3.588-8.887 8.138-17.223 13.603-25.0816 1.028-1.476-1.381-2.8734-2.401-1.4094-5.606 8.0587-10.206 16.64-13.883 25.748-.673 1.665 2.017 2.389 2.681.743z"
                />
                <path
                    id="path186"
                    d="M478.212 118.291c4.512-8.834 9.736-17.199 15.691-25.1244 1.077-1.4347-1.336-2.828-2.402-1.4093-5.954 7.924-11.178 16.2907-15.692 25.1227-.814 1.597 1.584 3.012 2.403 1.411z"
                />
                <path
                    id="path188"
                    d="M480.339 119.872c4.378-7.193 9.669-13.636 15.85-19.339 1.322-1.2184-.649-3.1904-1.966-1.9757-6.352 5.8597-11.787 12.5147-16.286 19.9037-.937 1.539 1.468 2.943 2.402 1.411z"
                />
            </g>
            <g id="right-scarf" fill="#F4D7D7">
                <path
                    id="path190"
                    d="M794.76 54.584c-.703 9.5-1.283 18.992-2.411 28.4546-.213 1.7814 2.568 1.7667 2.78 0 1.13-9.4626 1.71-18.9546 2.412-28.4546.134-1.7934-2.649-1.7854-2.781 0z"
                />
                <path
                    id="path192"
                    d="M791.104 55.4972c-1.601 9.124-1.597 18.3361-4.487 27.2134-.557 1.7134 2.128 2.4467 2.683.7427 2.896-8.9027 2.881-18.0654 4.487-27.2134.308-1.7586-2.371-2.5133-2.683-.7427z"
                />
                <path
                    id="path194"
                    d="M786.593 55.6933c-1.288 8.912-.742 17.9293-2.549 26.7747-.357 1.7546 2.321 2.5053 2.683.7427 1.808-8.8627 1.26-17.8547 2.548-26.7747.254-1.7587-2.424-2.5187-2.682-.7427z"
                />
                <path
                    id="path196"
                    d="M782.195 56.1547c-.391 7.8774-.671 15.356-2.9 22.9827-.506 1.728 2.177 2.4666 2.68.7426 2.306-7.888 2.596-15.5719 3-23.7253.09-1.796-2.691-1.792-2.78 0z"
                />
                <path
                    id="path198"
                    d="M777.333 56.524c-.256 7.144-.212 14.2694-.876 21.3947-.166 1.7907 2.616 1.7787 2.782 0 .664-7.1253.62-14.2507.874-21.3947.066-1.7987-2.716-1.796-2.78 0z"
                />
                <path
                    id="path200"
                    d="M773.705 50.2947c-1.461 12.1333-1.465 24.1667-1.214 36.3653.037 1.796 2.817 1.8027 2.781 0-.251-12.1986-.247-24.232 1.215-36.3653.214-1.7813-2.568-1.7667-2.782 0z"
                />
                <path
                    id="path202"
                    d="M693.695 272.273c2.317-4.838 4.832-9.758 8.248-13.921 3.166-3.859 5.282-5.563 9.097-7.659.62-.328 1.251-.634 1.889-.92-2.129.995.822-.189 1.091-.274 1.497-.482 3.044-.792 4.589-1.064-2.862.505 1.631-.098 2.346-.135 1.661-.089 3.329-.075 4.993-.056 6.369.073 12.432.143 18.371-2.516 8.588-3.844 11.993-14.852 13.478-23.352 1.84-10.536 1.086-21.156 4.879-31.309 2.019-5.403 6.62-9.467 9.805-14.114 3.57-5.204 4.41-12.148 4.776-18.266.319-5.272-.15-10.576-.004-15.855.16-5.74 2.674-9.817 5.704-14.605 3.967-6.267 7.35-12.55 9.328-19.758 1.916-6.982 2.324-14.465 2.826-21.6597.21-3.0413 1.302-7.84-.814-9.8747-2.116-2.0333-7.438-1.2999-10.31-1.2999-2.911 0-8.019-1.92-9.899-.1907-2.239 2.0653-.996 8.0613-1.225 11.3653-.416 5.9694-.938 11.7361-2.526 17.5227-1.6 5.831-5.509 10.809-8.634 15.889-6.883 11.187-6.675 22.691-6.622 35.35.012 3.165.246 7.657-1.83 10.176-5.07 6.152-8.946 12.01-12.027 19.378-4.147 9.912-3.371 21.176-4.88 31.308-.365 2.447-.989 4.831-1.731 7.186-.344 1.097-3.052 5.376-.645 2.306-1.721 2.196 2.039-.986-.371.276 3.131-1.641.538-.354-.66-.141 3.798-.68.002-.124-.966-.1-1.223.029-2.448-.001-3.67-.024-3.034-.055-6.056-.093-9.085.12-21.412 1.503-35.779 16.247-44.732 34.936-2.629 5.489-1.549 12.031 3.989 15.291 4.912 2.889 12.592 1.477 15.222-4.01z"
                />
            </g>
            <path
                id="path204"
                d="M624.235 266.632c3.904 10.527 52.221 10.592 56.622 0h-56.622z"
                fill="#F04033"
            />
            <path
                id="path206"
                d="M624.235 266.632c3.904 10.527 52.221 10.592 56.622 0h-56.622z"
                stroke="#FBC020"
                stroke-width="28.0267"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                id="path210"
                d="M633.152 248.661c2.405 7.122 36.025 7.308 38.789 0h-38.789z"
                stroke="#F89C23"
                stroke-width="21.2765"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                id="path212"
                d="M649.636 66.8266c-22.813-1.6386-46.751 5.8013-56.029 25.7547-17.507 45.0417-8.032 109.9697-29.951 122.0017-8.065 4.666-33.84 3.96-28.707 27.861 3.94 16.976 22.026 17.636 42.314 9.056 24.497-11.576 22.009-57.556 22.604-97.653.294-13.215 33.094-14.936 49.769-16.42V66.8266z"
                fill="#FBC273"
            />
            <path
                id="path214"
                d="M649.568 66.8266c22.812-1.6386 55.668 6.7094 64.947 26.6627 17.506 45.0427-.886 109.0617 21.032 121.0937 8.065 4.666 33.84 3.96 28.706 27.861-3.94 16.976-22.025 17.636-42.313 9.056-24.497-11.575-16.448-57.556-17.041-97.653-.296-13.215-38.656-14.936-55.331-16.42V66.8266z"
                fill="#FBC273"
            />
            <path
                id="path216"
                d="M740.899 209.672C718.981 197.64 730.7 133.62 713.193 88.5773c-8.63-18.556-28.75-27.0706-51.121-26.8533v-.0041h-26.421c-22.152.164-40.088 8.708-48.528 26.8574-17.507 45.0427-5.79 109.0627-27.706 121.0947-8.066 4.665-32.212 2.292-27.078 26.193 3.94 16.976 17.06 19.304 37.348 10.724 24.497-11.576 22.232-57.553 22.602-97.653.255-27.623 119.912-29.611 120.186 0 .372 40.1-7.455 86.077 17.042 97.653 20.287 8.58 33.407 6.252 37.347-10.724 5.133-23.901-17.899-21.528-25.965-26.193z"
                fill="red"
            />
            <path
                id="path222"
                d="M595.415 158.335l-7.423-7.059-1.889 20.691 4.656.81 4.656-14.442z"
                fill="#6C1D24"
            />
            <path
                id="path224"
                d="M681.228 225.595c5.337-4.102 10.097-8.875 9.056-13.312-1.283-6.63-20.528-7.184-32.872-27.634-1.807 1.81-3.383 1.859-5.368 1.774v-3.044c3.737.006 4.708-2.256 5.676-4.696l46.484 19.952c-4.603 11.998-9.563 23.778-22.473 30.666l-.503-3.706z"
                fill="#F0755D"
            />
            <path
                id="path226"
                d="M623.163 225.595c-5.339-4.102-10.099-8.875-9.058-13.312 1.283-6.63 20.53-7.184 32.874-27.634 2.053 1.704 3.381 1.871 5.366 1.786v-3.084c-3.672-.106-4.242-2.207-5.21-4.647l-46.95 19.931c4.604 11.998 9.564 23.777 22.474 30.666l.504-3.706z"
                fill="#F0755D"
            />
            <path
                id="path228"
                d="M709.437 158.275l7.516-7.148 2.12 20.472-4.656.809-4.98-14.133z"
                fill="#6C1D24"
            />
            <path
                id="path230"
                d="M634.889 147.059c2.462-4.566 29.066-4.544 33.902-.028-9.787 8.91-9.572 18.872-8.41 32.11-3.66-5.49-12.158-5.982-15.688 0 .508-11.474-2.542-21.425-9.804-32.082z"
                fill="#4A1C14"
            />
            <path
                id="path234"
                d="M700.433 147.945c-2.648-1.62-5.584-2.916-8.8-3.844l-.132-.034c-6.541-1.643-12.904-1.888-18.398-.827-3.816.727-7.21 2.089-9.935 4.057-4.349 3.118-6.896 7.655-7.385 13.108-1.124 9.131 3.158 17.29 11.124 26.007 1.946 2.135 4.025 4.033 6.196 5.688.121.1.233.189.356.279 6.106 4.605 12.904 6.829 18.709 6.672.212.01 5.261-.235 8.265-1.419.122-.033.234-.089.344-.135 2.504-1.084 5.074-2.637 7.343-4.682 5.184-4.65 7.652-8.874 7.831-16.182v-.18c-.29-12.516-5.928-22.641-15.518-28.508zm0 41.428c-3.304 1.419-7.064 1.531-10 .96-5.296-1.273-11.002-2.145-17.33-8.782-.235-.224-.458-.471-.68-.716-6.262-6.907-8.631-11.756-8.932-19.524l.022-.167c.279-3.241 1.635-5.689 4.172-7.511 1.468-1.05 3.315-1.844 5.418-2.39 4.638-1.186 10.544-1.096 16.452.368 4.326 1.262 7.976 3.33 10.878 6.08 4.827 4.57 7.552 11.041 7.764 18.853-.233 7.185-3.649 11.041-7.764 12.829z"
                fill="#6C1D24"
            />
            <path
                id="path252"
                d="M663.539 161.308l.017-.164c.277-3.236 1.639-5.687 4.167-7.505 1.232-.886 2.733-1.59 4.429-2.114 4.769-1.466 11.107-1.492 17.452.09 3.841 1.122 7.16 2.876 9.876 5.178 5.448 4.614 8.539 11.422 8.751 19.746-.246 7.76-4.2 11.628-8.751 13.208-3.043 1.065-6.364 1.417-9.007.909-3.301-.408-11.397-2.523-18.012-9.823l-.309-.345c-6.027-6.727-8.327-11.536-8.613-19.18z"
                fill="#FAA"
                stroke="#F9D1D8"
                stroke-width="2.40721"
                stroke-miterlimit="10"
            />
            <path
                id="path256"
                d="M588.863 176.453v.18c.177 7.308 2.648 11.532 7.83 16.182 2.27 2.045 4.839 3.598 7.342 4.682.112.046.222.102.345.135 3.004 1.184 8.053 1.429 8.265 1.419 5.807.157 12.603-2.067 18.711-6.672.121-.09.232-.179.355-.279 2.169-1.655 4.25-3.553 6.197-5.688 7.964-8.717 12.245-16.876 11.123-26.007-.49-5.453-3.036-9.99-7.387-13.108-2.725-1.968-6.117-3.33-9.933-4.057-5.495-1.061-11.858-.816-18.399.827l-.132.034c-3.215.928-6.152 2.224-8.8 3.844-9.588 5.867-15.228 15.992-15.517 28.508zm7.753.091c.211-7.812 2.936-14.283 7.764-18.853 2.904-2.751 6.552-4.818 10.879-6.08 5.906-1.464 11.814-1.554 16.452-.368 2.102.546 3.949 1.34 5.418 2.39 2.536 1.822 3.894 4.27 4.171 7.51l.021.168c-.298 7.768-2.669 12.617-8.932 19.524-.222.245-.445.492-.678.716-6.33 6.637-12.035 7.509-17.331 8.782-2.936.571-6.696.459-10-.96-4.116-1.788-7.531-5.644-7.764-12.829z"
                fill="#6C1D24"
            />
            <path
                id="path274"
                d="M632.661 180.488l-.309.345c-6.613 7.3-14.711 9.415-18.012 9.823-2.644.508-5.965.156-9.007-.909-4.55-1.582-8.506-5.448-8.75-13.208.212-8.324 3.302-15.132 8.75-19.746 2.716-2.302 6.035-4.056 9.876-5.178 6.346-1.582 12.682-1.556 17.452-.09 1.698.524 3.198 1.228 4.428 2.114 2.53 1.818 3.891 4.269 4.168 7.505l.016.164c-.285 7.644-2.584 12.453-8.612 19.18z"
                fill="#FAA"
                stroke="#F9D1D8"
                stroke-width="2.40721"
                stroke-miterlimit="10"
            />
            <path
                id="path278"
                d="M655.983 710.168c-124.164 1.868-226.155-86.599-227.8-197.585-.223-14.886 1.374-29.419 4.6-43.427 14.697-63.835 63.257-116.883 128.337-143.157 6.155-2.49-.909 36.877 3.472 40.01 14.956 10.696 36.313 45.808 60.811 45.447 16.362-.243 103.969-12.801 119.394-10.057 8.071 1.434 4.635-18.326.827-38.086-3.941-20.441-8.281-40.881-.695-37.86 51.668 20.546 93.074 58.096 115.218 104.83 5.664 11.956 10.08 24.505 13.072 37.541 2.837 12.296 4.406 25.001 4.6 38.027 1.646 110.977-97.672 202.458-221.836 204.317z"
                fill="#A00"
            />
            <path
                id="path280"
                d="M873.219 467.824c-20.818 90.489-109.718 159.315-217.236 160.92-109.611 1.644-201.938-67.093-223.202-159.588 8.455-36.723 28.12-69.887 55.696-96.424 20.355-19.593 45.007-35.576 72.643-46.733l.077-.03c1.955 24.923 42.77 44.282 92.53 43.532 48.326-.718 87.649-20.145 91.01-44.125l.192.077c27.278 10.847 51.698 26.442 71.935 45.626 18.088 17.13 32.827 37.141 43.283 59.204 5.664 11.956 10.08 24.505 13.072 37.541z"
                fill="#D40000"
            />
            <path
                id="path298"
                d="M746.699 416.928c-26.007-19.472-58.258-30.999-93.187-30.999H652.493c-32.55 0-62.773 10.011-87.781 27.135-8.023-2.713-16.471-4.275-25.277-4.444-18.774-9.743-35.816-21.855-50.626-35.891 16.62-16.004 36.108-29.592 57.744-40.097.279-.145.556-.28.835-.413 4.516-2.176 9.119-4.223 13.819-6.128.262 3.137 1.141 6.189 2.576 9.12 1.652 3.374 4.038 6.59 7.073 9.608 4.56 4.534 10.581 8.618 17.748 12.097 1.776.863 3.627 1.689 5.539 2.475 12.496 5.134 27.804 8.588 44.606 9.724 3.984.269 8.052.408 12.187.408.531 0 1.061-.002 1.595-.007.512-.004 1.02-.009 1.533-.016 48.239-.715 87.508-20.083 90.987-43.999.009.004.02.01.03.014.043.016.084.033.128.05.008.003.016.007.024.01.043.017.086.034.127.05.011.004.017.007.027.011.045.019.09.036.134.055.006.001.011.004.016.005.046.019.09.036.135.055.004.001.013.005.019.006.048.02.096.039.144.059.001 0 .001 0 .002.001 1.156.466 2.307.939 3.452 1.42.004.003.008.004.012.006.046.02.09.037.135.057.007.003.013.005.02.008.041.017.087.036.128.053.011.006.019.008.029.014.04.017.082.034.122.05.009.004.021.01.03.014.04.017.079.033.118.05.016.006.029.012.044.019.034.015.07.029.105.044.021.009.039.016.06.025.029.014.061.027.091.039l.084.036c.021.009.044.019.065.028 25.104 10.709 47.64 25.489 66.532 43.399.055.05.111.104.164.154-19.789 19.008-43.637 34.614-70.329 45.695z"
                fill="#F55"
            />
            <path
                id="path300"
                d="M758.472 331.191c-7.143 30.486-50.057 52.714-104.987 53.53-.989.022-1.98.022-2.969.022-25.296 0-49.356-4.827-68.044-13.69-20.047-9.51-32.581-23-35.919-38.421.279-.145.556-.28.835-.413 4.593-2.214 9.276-4.292 14.06-6.226l.079-.022c1.956 24.921 42.77 44.278 92.537 43.529 48.321-.716 87.643-20.149 91.004-44.121l.199.078c4.484 1.776 8.888 3.687 13.205 5.734z"
                fill="#D40000"
            />
            <path
                id="path304"
                d="M684.265 708.163c-2.416-3.36-4.873-6.814-7.185-10.458 33.047-5.14 62.664-20.694 85.433-43.218 66.471-30.62 112.026-87.992 115.15-153.954.021.483.04.966.058 1.45.036 1.025.064 2.053.084 3.084.006.262.01.524.012.786 1.52 102.404-82.926 188.212-193.552 202.31z"
                fill="maroon"
            />
            <path
                id="path308"
                d="M589.597 702.295c-73.726-19.11-131.901-71.179-152.936-137.486 21.856 40.051 61.522 72.858 109.942 92.676 12.829 12.012 27.685 21.884 43.982 29.036-.709 5.263-1.052 10.536-.988 15.774z"
                fill="maroon"
            />
            <path
                id="path310"
                d="M809.088 542.744c0 86.611-69.88 156.815-156.088 156.815s-156.087-70.204-156.087-156.815c0-6.996.455-13.88 1.344-20.631 4.272-32.721 18.59-62.258 39.758-85.402 28.52-31.214 69.477-50.782 114.985-50.782 44.061 0 83.861 18.339 112.239 47.843 21.157 21.981 35.973 50.177 41.48 81.592 1.557 8.896 2.369 18.037 2.369 27.38z"
                fill="#003380"
            />
            <path
                id="path312"
                d="M807.231 515.364c-10.067 76.843-75.519 136.152-154.754 136.152-76.886 0-140.804-55.867-153.708-129.403 10.046-76.865 75.498-136.184 154.743-136.184 76.909 0 140.827 55.878 153.719 129.435z"
                fill="#0055D4"
            />
            <path
                id="path314"
                d="M764.732 433.772c-28.521 31.212-69.489 50.781-114.997 50.781-44.06 0-83.862-18.338-112.227-47.842 28.521-31.214 69.477-50.782 114.985-50.782 44.062 0 83.863 18.339 112.239 47.843z"
                fill="#2A7FFF"
            />
            <g id="propeller">
                <path
                    id="path586"
                    d="M573.808 364.367c-45.427-12.154-85.315 15.761-76.059 53.06 13.271 53.482 79.351 5.702 107.051 85.777 7.073 20.449 22.572 6.609 1.376 29.519l9.176-4.852 13.529 7.497c11.155-11.568 17.395-22.889 21.052-46.051.252-51.488-24.209-111.061-76.125-124.95z"
                    fill="#FDF6FB"
                />
                <path
                    id="path588"
                    d="M499.12 392.701l-.052-.308c-2.912 7.503-3.563 15.991-1.319 25.034 13.271 53.484 79.351 5.702 107.051 85.777 7.073 20.449 22.572 6.609 1.376 29.519l9.176-4.852 4.464 2.474c7.801-5.201 12.687-11.664 11.607-17.208-3.172-16.305-8.812-45.52-44.147-69.946-35.335-24.428-84.264-19.698-88.156-50.49z"
                    fill="#F0D2E3"
                />
                <path
                    id="path590"
                    d="M853.164 568.101c33.192-33.448 29.073-82.11-7.707-92.706-52.738-15.196-44.592 66.189-127.466 50.252-21.166-4.07-16.984 16.334-26.134-13.563l-.405 10.411-13.228 8.022c4.395 15.49 11.033 26.579 29.171 41.343 44.257 25.963 107.84 34.465 145.769-3.759z"
                    fill="#FDF6FB"
                />
                <path
                    id="path592"
                    d="M866.084 488.951l.291.108c-5.011-6.284-12.002-11.095-20.918-13.664-52.738-15.196-44.592 66.189-127.466 50.252-21.166-4.07-16.984 16.334-26.134-13.563l-.405 10.411-4.367 2.646c.584 9.387 3.712 16.87 9.031 18.703 15.641 5.393 43.644 15.093 82.368-3.437s59.111-63.467 87.6-51.456z"
                    fill="#F0D2E3"
                />
                <path
                    id="path594"
                    d="M537.865 709.291c12.238 45.601 56.244 66.349 83.77 39.648 39.466-38.29-34.762-71.892 20.414-136.03 14.091-16.381-5.588-22.945 24.758-15.957l-8.772-5.559-.3-15.52c-15.55-3.921-28.43-3.688-50.223 4.71-44.509 25.526-83.631 76.593-69.647 128.708z"
                    fill="#FDF6FB"
                />
                <path
                    id="path596"
                    d="M599.635 760.108l-.239.199c7.923-1.218 15.565-4.895 22.239-11.368 39.466-38.288-34.762-71.892 20.414-136.03 14.09-16.381-5.588-22.945 24.758-15.957l-8.772-5.559-.099-5.121c-8.383-4.187-16.396-5.205-20.636-1.495-12.469 10.914-34.832 30.427-38.22 73.384-3.389 42.958 25.152 83.164.555 101.947z"
                    fill="#F0D2E3"
                />
                <path
                    id="path598"
                    d="M720.849 544.531c.358 27.261-15.265 51.056-38.144 62.333-7.692 3.797-16.189 6.183-25.189 6.804-1.196.081-2.408.144-3.619.161-14.142.235-27.326-3.886-38.286-11.15-6.123-4.023-11.535-9.044-16.055-14.823-8.849-11.303-14.197-25.532-14.405-41.039-.056-4.446.304-8.81 1.061-13.032 5.628-31.534 32.841-55.726 65.883-56.276 4.942-.089 9.773.36 14.429 1.306 12.849 2.556 24.4 8.784 33.511 17.549 10.085 9.711 17.178 22.536 19.754 36.989.646 3.627 1.012 7.363 1.06 11.178z"
                    fill="#2C89A0"
                />
                <path
                    id="path600"
                    d="M700.033 496.365c-9.109-8.762-20.664-14.996-33.508-17.546-4.658-.944-9.492-1.396-14.426-1.312-33.044.557-60.26 24.745-65.884 56.281.092.504.186.996.288 1.489 2.12 10.579 6.674 20.234 13.05 28.388 4.528 5.782 9.936 10.799 16.052 14.828 10.97 7.262 24.15 11.387 38.298 11.146 1.208-.022 2.416-.075 3.616-.159 8.997-.629 17.493-3.001 25.188-6.811 18.841-9.276 32.748-27.042 36.874-48.252.074-.357.139-.702.204-1.06-2.574-14.461-9.666-27.284-19.752-36.992z"
                    fill="#2C5AA0"
                />
                <path
                    id="path602"
                    d="M609.215 515.693c18.805-29.793 71.718-29.048 92.021 3.519"
                    stroke="#FDF6FB"
                    stroke-width="10.2254"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path604"
                    d="M513.525 380.739c-18.108 15.604-33.624 34.141-45.824 54.862"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path606"
                    d="M571.112 746.897c24.551 10.176 51.371 15.922 79.468 16.274"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path608"
                    d="M819.753 404.975c-2.98-3.543-6.072-6.988-9.269-10.331"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path610"
                    d="M870.075 529.32c-.552-7.78-1.507-15.44-2.847-22.953-5.552-31.172-17.695-59.971-34.917-84.932"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path612"
                    d="M671.159 738.743c12.486-1.136 24.62-3.468 36.294-6.878"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path614"
                    d="M549.459 708.924c28.668 18.564 62.55 29.636 98.912 30.576"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path616"
                    d="M846.861 554.905c.208-4.128.294-8.286.238-12.476-.135-10.854-1.178-21.477-3.015-31.793-4.308-24.181-13.063-46.76-25.38-66.875"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path618"
                    d="M491.193 499.111c-1.577 5.516-2.885 11.145-3.906 16.872"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path620"
                    d="M574.584 395.56c-33.051 17.78-59.552 46.195-75.011 80.617"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path622"
                    d="M820.516 576l.285-.047c1.956-10.737 2.935-21.81 2.786-33.129-.119-9.532-1.034-18.86-2.648-27.92-5.8-32.568-20.791-61.825-42.162-85.048"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path624"
                    d="M593.173 412.607c-42.698 19.868-74.221 59.757-82.741 107.494-.709 3.955-1.256 7.967-1.637 12.027"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path626"
                    d="M686.817 688.5c5.954-1.389 11.767-3.145 17.416-5.243"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path628"
                    d="M558.247 655.039c-.136.389-.266.78-.398 1.169 4.99 4.385 10.262 8.447 15.827 12.103 23.58 15.625 51.944 24.492 82.365 23.989 2.606-.04 5.214-.175 7.784-.349"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path630"
                    d="M607.859 431.913c-32.023 13.235-57.224 39.643-68.98 72.416"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path632"
                    d="M568.463 633.864c5.566 5.463 11.638 10.399 18.172 14.692 15.769 10.449 34.09 17.296 53.804 19.429"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
                <path
                    id="path634"
                    d="M773.179 574.091c2.336-9.77 3.521-19.979 3.384-30.478-.088-6.886-.748-13.626-1.915-20.172-2.379-13.352-6.895-25.934-13.176-37.385"
                    stroke="#FDF6FB"
                    stroke-width="6.68976"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
            <path
                id="path636"
                d="M744.371 324.711c-2.587 24.352-42.25 44.194-91.108 44.929-49.947.747-90.887-18.761-92.567-43.821"
                stroke="#3D7283"
                stroke-width="19.4109"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                id="path638"
                d="M566.551 145.509v27.618"
                stroke="#6C1D24"
                stroke-width="22.8021"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                id="path640"
                d="M573.981 132.603c8.302 7.361 8.215 49.326 0 54.977"
                stroke="#491C14"
                stroke-width="17.7349"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                id="path642"
                d="M738.215 145.509v27.618"
                stroke="#6C1D24"
                stroke-width="22.8021"
                stroke-miterlimit="10"
                stroke-linecap="round"
            />
            <path
                id="path644"
                d="M730.784 132.604c-8.301 7.36-8.216 49.325 0 54.976"
                stroke="#491C14"
                stroke-width="17.7349"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: ['width', 'height'],
};
</script>

<style scoped>
#red-plane-kid {
    animation-name: flying;
    animation-duration: 12s;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    transform-origin: center;
}
@keyframes flying {
    0% {
        transform: rotate(10deg) scale(0.7) translateX(-80px);
    }
    33% {
        transform: rotate(-5deg) scale(0.9) translateX(200px);
    }
    66% {
        transform: rotate(-15deg) scale(0.8) translateX(-200px);
    }
    100% {
        transform: rotate(10deg) scale(1) translateX(80px);
    }
}
#propeller {
    animation-name: propeller;
    animation-duration: 0.1s;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    transform-origin: center;
}
@keyframes propeller {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
</style>
