<template>
    <v-app-bar
        absolute
        color="transparent"
        src="../assets/header-bg.svg"
        flat
        height="500"
        app
    >
        <v-container>
            <v-row
                align="center"
                justify="center"
                v-if="$vuetify.breakpoint.smAndUp"
            >
                <logo-balloon height="400"></logo-balloon>
            </v-row>
            <v-row align="start" justify="center" v-else>
                <logo-balloon-mobile height="400"></logo-balloon-mobile>
            </v-row>
        </v-container>
    </v-app-bar>
</template>

<script>
import LogoBalloon from './svg/LogoBalloon.vue';
import LogoBalloonMobile from './svg/LogoBalloonMobile.vue';

export default {
    name: 'AppBar',
    components: {
        LogoBalloon,
        LogoBalloonMobile,
    },
    created() {
        document.title = 'Sunny Center - Jardin Infantil y Salacuna';
    },
};
</script>

<style lang="scss"></style>
