<template>
    <v-app>
        <app-bar></app-bar>
        <navigation-drawer></navigation-drawer>

        <v-main>
            <router-view />
            <app-footer />
        </v-main>

        <menu-cloud />
    </v-app>
</template>

<script>
import AppBar from './components/AppBar.vue';
import MenuCloud from './components/TopNavigation/MenuCloud.vue';
import NavigationDrawer from './components/TopNavigation/NavigationDrawer.vue';
import AppFooter from './components/AppFooter.vue';

export default {
    name: 'App',
    components: {
        AppBar,
        MenuCloud,
        NavigationDrawer,
        AppFooter,
    },
    data: () => ({
        //
    }),
};
</script>

<style lang="scss">
html {
    font-size: 18px;
}
/* #app {
    background: pink url('./assets/18716256.jpg') center top no-repeat;
    background-size: cover;
} */
#app {
    background-color: #42a5f5;
    color: #fefefe;
}
@import url('https://fonts.googleapis.com/css2?family=Atma:wght@400;700&display=swap');
$font-family: 'Atma', cursive;
.v-application {
    [class*='text-'] {
        color: #fefefe;
        font-family: $font-family, sans-serif !important;
        word-break: break-word;
    }
    color: #fefefe;
    font-family: $font-family, sans-serif !important;
    word-break: break-word;
}

// Global Variables
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

// Margin & Paddings
$i: 1;
$j: 1;
$x: 128;
@while $i < $x {
    $i: $i + 4;
    .pt-#{$j} {
        padding-top: #{$i - 1}px;
    }
    .pl-#{$j} {
        padding-left: #{$i - 1}px;
    }
    .pb-#{$j} {
        padding-bottom: #{$i - 1}px;
    }
    .pr-#{$j} {
        padding-right: #{$i - 1}px;
    }
    .mt-#{$j} {
        margin-top: #{$i - 1}px;
    }
    .ml-#{$j} {
        margin-left: #{$i - 1}px;
    }
    .mb-#{$j} {
        margin-bottom: #{$i - 1}px;
    }
    .mr-#{$j} {
        margin-right: #{$i - 1}px;
    }
    $j: $j + 1;
}
</style>
