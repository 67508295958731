<template>
    <v-footer padless color="light-green darken-1">
        <div class="footer-bg"></div>

        <v-container class="mt-10">
            <v-row align="start" justify="center" no-gutters>
                <v-col cols="12" sm="6" md="7" lg="6">
                    <contact-form
                        color="transparent"
                        alert="white--text"
                        taller="Todos"
                        heading="text-h4 text-sm-h5 white--text font-weight-bold text-uppercase"
                        :theme="true"
                        loader="white"
                    />
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="6" md="5" lg="5" offset-lg="1">
                    <v-card color="transparent" elevation="0">
                        <v-card-title
                            class="
                                text-uppercase text-h4 text-sm-h5
                                font-weight-bold
                                white--text
                            "
                            :class="{
                                'text-h5':
                                    $vuetify.breakpoint.sm ||
                                    $vuetify.breakpoint.lg,
                            }"
                            >Informaci&oacute;n</v-card-title
                        >

                        <v-container>
                            <v-row align="start" justify="start">
                                <!-- Direccion -->
                                <v-col cols="12">
                                    <v-card color="transparent" elevation="0">
                                        <v-card-text>
                                            <v-row
                                                align="start"
                                                justify="center"
                                            >
                                                <v-col cols="1">
                                                    <v-icon color="white--text">
                                                        mdi-map
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="11">
                                                    <h1
                                                        class="
                                                            text-h5
                                                            mb-3
                                                            pl-2
                                                        "
                                                    >
                                                        Direcci&oacute;n
                                                    </h1>
                                                    <p
                                                        class="
                                                            text-body-1
                                                            white--text
                                                        "
                                                    >
                                                        El Vergel 2465,
                                                        Providencia
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- Telefonos -->
                                    <v-card color="transparent" elevation="0">
                                        <v-card-text>
                                            <v-row
                                                align="start"
                                                justify="center"
                                            >
                                                <v-col cols="1">
                                                    <v-icon color="white--text">
                                                        mdi-phone
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="11">
                                                    <h1
                                                        class="
                                                            text-h5
                                                            mb-3
                                                            pl-2
                                                        "
                                                    >
                                                        Tel&eacute;fonos
                                                    </h1>
                                                    <p
                                                        class="
                                                            text-body-1
                                                            white--text
                                                        "
                                                    >
                                                        +56 2 29190213
                                                    </p>
                                                    <p
                                                        class="
                                                            text-body-1
                                                            white--text
                                                        "
                                                    >
                                                        +56 9 61523172
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <!-- RRSS -->
                                    <v-card color="transparent" elevation="0">
                                        <v-card-text>
                                            <v-row
                                                align="start"
                                                justify="center"
                                            >
                                                <v-col cols="1">
                                                    <v-icon color="white--text">
                                                        mdi-share-variant
                                                    </v-icon>
                                                </v-col>
                                                <v-col cols="11">
                                                    <h1
                                                        class="
                                                            text-h5
                                                            mb-3
                                                            pl-2
                                                        "
                                                    >
                                                        S&iacute;guenos en RRSS
                                                    </h1>
                                                    <p
                                                        class="
                                                            text-body-1
                                                            white--text
                                                        "
                                                    >
                                                        <v-icon
                                                            class="mr-2"
                                                            color="white"
                                                        >
                                                            mdi-instagram
                                                        </v-icon>
                                                        <span>
                                                            @jardininfantilthesunnycenter
                                                        </span>
                                                    </p>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
            <v-row align="center" justify="center">
                <v-col cols="12">
                    <v-card
                        color="white"
                        rounded="lg"
                        elevation="4"
                        class="pa-4 mb-16"
                        id="card-two"
                    >
                        <google-map />
                    </v-card>
                </v-col>
            </v-row>
            <v-divider dark class="mt-3"></v-divider>
            <v-row align="center" justify="center">
                <v-col cols="12" class="mt-3 mb-2 text-center">
                    <small class="green--text text--darken-4">
                        Todos los derechos reservados &reg; Jardin Infantil y
                        Sala Cuna - SunnyCenter
                    </small>
                </v-col>
            </v-row>
        </v-container>
    </v-footer>
</template>

<script>
import ContactForm from './ContactForm.vue';
import GoogleMap from './GoogleMap.vue';

export default {
    components: {
        ContactForm,
        GoogleMap,
    },
    data() {
        return {};
    },
};
</script>

<style lang="scss" scoped>
.footer-bg {
    background: transparent url('../assets/landscape-mountains-pines.svg') top
        center no-repeat;
    height: 300px;
    width: 100%;
    margin-top: -200px;
}
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}
</style>
