var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',[_c('v-row',{class:{
                'mt-n16': _vm.$vuetify.breakpoint.xs,
                'mt-n6': _vm.$vuetify.breakpoint.smAndUp,
            },attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-12",attrs:{"cols":"12"}},[_c('h1',{staticClass:"\n                        text-h2 text-center text-uppercase\n                        font-weight-bold\n                        home-title\n                    ",class:{ 'text-h3': _vm.$vuetify.breakpoint.sm },attrs:{"id":"home-title"}},[_vm._v(" Jardin infantil y Sala Cuna ")])])],1)],1),_c('v-container',[_c('v-row',{staticClass:"mt-12",attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"5"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"white","rounded":"lg","elevation":"4","id":"card-one"}},[_c('v-card-title',{staticClass:"\n                            text-uppercase text-h4\n                            font-weight-bold\n                            deep-purple--text\n                        ",class:{
                            'text-h5':
                                _vm.$vuetify.breakpoint.sm ||
                                _vm.$vuetify.breakpoint.lg,
                        }},[_vm._v("Plan Vuelta Segura")]),_c('v-card-text',{staticClass:"deep-purple--text text-h6"},[_vm._v("Sabemos lo importante que es la seguridad y bienestar de nuestros niños y niñas, por eso contamos con protocolo Covid-19 aprobado por Ministerio de Educación y Seremi de Salud.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-end",attrs:{"text":"","x-large":"","color":"deep-purple"},on:{"click":function($event){_vm.showCovidContent = !_vm.showCovidContent}}},[_vm._v(" ver más "),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" "+_vm._s(_vm.showCovidContent ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1)],1),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCovidContent),expression:"showCovidContent"}]},[_c('v-divider'),_vm._l((_vm.protocolo),function(item,i){return _c('v-card-text',{key:i},[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{attrs:{"color":"deep-purple"}},[_vm._v(" mdi-arrow-right-circle-outline ")])],1),_c('v-col',{attrs:{"cols":"11"}},[_c('p',[_vm._v(_vm._s(item.text))])])],1)],1)}),_c('v-card-text',[_c('v-row',{attrs:{"align":"start","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v(" Frente a un caso positivo nuestro proceder será avisar de manera inmediata a los apoderados del nivel afectado y/o jardín Infantil según sea la causante. ")])])],1)],1)],2)])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"5"}},[_c('v-card',{staticClass:"pa-4",attrs:{"color":"white","rounded":"lg","elevation":"4","id":"card-two"}},[_c('v-card-title',{staticClass:"\n                            text-uppercase text-h4\n                            font-weight-bold\n                            official-red--text\n                        ",class:{
                            'text-h5':
                                _vm.$vuetify.breakpoint.sm ||
                                _vm.$vuetify.breakpoint.lg,
                        }},[_vm._v("Summer School")]),_c('v-card-text',{staticClass:"official-red--text text-h6"},[_vm._v("Las clases se termina en diciembre, pero la diversión continua en enero con nuestro Summer School, una instancia para seguir divirtiéndonos con entretenidas actividades y talleres.")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-end",attrs:{"text":"","x-large":"","color":"official-red","to":"/talleres"}},[_vm._v(" ver más "),_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)],1)],1),_c('v-container',{staticClass:"mt-10",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('red-plain-with-clouds')],1)],1)],1),_c('section-levels')],1)}
var staticRenderFns = []

export { render, staticRenderFns }