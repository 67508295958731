<template>
    <v-container>
        <v-row align="start" justify="center">
            <v-col cols="12" md="6">
                <v-card
                    elevation="2"
                    color="purple accent-4"
                    rounded="xl"
                    dark
                    class="pa-4"
                >
                    <v-card-title> Jornada Ma&ntilde;ana </v-card-title>
                    <v-container>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-card-title> Nivel </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> Starlight </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-card-title> Ingreso </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> 08:00 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-card-title> Salida </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> 12:00 </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> Sunshine </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 08:15 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 12:15 </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> Rainbow </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 08:30 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 12:30 </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card
                    elevation="2"
                    color="orange accent-4"
                    rounded="xl"
                    dark
                    class="pa-4"
                >
                    <v-card-title> Jornada Tarde </v-card-title>
                    <v-container>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-card-title> Nivel </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> Starlight </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-card-title> Ingreso </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> 13:15 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-card-title> Salida </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text> 17:15 </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> Sunshine </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 13:30 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 17:30 </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row align="start" justify="center">
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> Rainbow </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 13:45 </v-card-text>
                            </v-col>
                            <v-col cols="4">
                                <v-divider></v-divider>
                                <v-card-text> 17:45 </v-card-text>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {};
</script>

<style></style>
