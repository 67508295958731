<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="2">
                <cloud-single
                    :height="$vuetify.breakpoint.name == 'xs' ? 120 : 180"
                    id="cloud--1"
                />
            </v-col>
            <v-col
                cols="12"
                sm="2"
                :style="
                    $vuetify.breakpoint.name == 'xs' ? 'margin-top: -150px' : ''
                "
            >
                <cloud-single
                    :height="$vuetify.breakpoint.name == 'xs' ? 80 : 130"
                    id="cloud--2"
                />
            </v-col>
            <v-col
                cols="12"
                sm="4"
                id="red-plain"
                :class="{ 'margin-top-n100': $vuetify.breakpoint.smAndDown }"
            >
                <red-plain id="red-plain" :height="svgSettings" />
            </v-col>
            <v-col cols="12" sm="2" v-if="$vuetify.breakpoint.smAndUp">
                <cloud-single height="150" id="cloud--3" />
            </v-col>
            <v-col cols="12" sm="2" v-if="$vuetify.breakpoint.smAndUp">
                <cloud-single height="180" id="cloud--4" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import RedPlain from '../components/svg/RedPlain.vue';
import CloudSingle from '../components/svg/CloudSingle.vue';

export default {
    components: {
        RedPlain,
        CloudSingle,
    },
    data() {
        return {
            svg: {
                height: 100,
            },
        };
    },
    methods: {
        svgHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm':
                    this.svg.height = 200;
                    break;
                case 'md':
                    this.svg.height = 300;
                    break;
                case 'lg':
                    this.svg.height = 300;
                    break;
                case 'xl':
                    this.svg.height = 400;
                    break;
                default:
                    this.svg.height = 200;
                    break;
            }
        },
    },
    computed: {
        svgSettings() {
            this.svgHeight();
            return this.svg.height;
        },
    },
};
</script>

<style lang="scss" scoped>
.margin-top-n100 {
    margin-top: -100px;
}
#cloud--1,
#cloud--2,
#cloud--3,
#cloud--4,
#red-plain {
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
#cloud--1 {
    animation-name: cloud-1;
    animation-duration: 12s;
}
#cloud--2 {
    animation-name: cloud-2;
    animation-duration: 12s;
}
#cloud--3 {
    animation-name: cloud-3;
    animation-duration: 11s;
}
#cloud--4 {
    animation-name: cloud-4;
    animation-duration: 11s;
}
#red-plain {
    animation-name: red-plain;
    animation-duration: 14s;
}
@keyframes cloud-1 {
    from {
        transform: translateX(300px) scale(1);
        opacity: 0.1;
    }
    to {
        transform: translateX(-50px) scale(0.5);
        opacity: 1;
    }
}
@keyframes cloud-2 {
    from {
        transform: translateX(-100px) scale(0.6);
        opacity: 0.2;
    }
    to {
        transform: translateX(200px) scale(1);
        opacity: 1;
    }
}
@keyframes cloud-3 {
    from {
        transform: translateX(50px) scale(0.4);
        opacity: 1;
    }
    to {
        transform: translateX(-200px) scale(1);
        opacity: 0.1;
    }
}
@keyframes cloud-4 {
    from {
        transform: translateX(-300px) scale(0.6);
        opacity: 1;
    }
    to {
        transform: translateX(0px) scale(1);
        opacity: 0;
    }
}
@keyframes red-plain {
    from {
        transform: translateX(-100px);
    }
    to {
        transform: translateX(50px);
    }
}
@media screen and (max-width: 639.9px) {
    @keyframes cloud-1 {
        from {
            transform: translateX(150px) scale(1);
            opacity: 0.1;
        }
        to {
            transform: translateX(-50px) scale(0.5);
            opacity: 1;
        }
    }
    @keyframes cloud-2 {
        from {
            transform: translateX(-100px) scale(0.6);
            opacity: 0.2;
        }
        to {
            transform: translateX(100px) scale(1);
            opacity: 1;
        }
    }
    @keyframes red-plain {
        from {
            transform: scale(0.6);
        }
        to {
            transform: scale(1);
        }
    }
}
@media screen and (min-width: 640px) and (max-width: 1024px) {
    @keyframes cloud-3 {
        from {
            transform: translateX(-100px) scale(0.4);
            opacity: 1;
        }
        to {
            transform: translateX(0px) scale(1);
            opacity: 0.4;
        }
    }
    @keyframes cloud-4 {
        from {
            transform: translateX(-120px) scale(0.6);
            opacity: 1;
        }
        to {
            transform: translateX(-300px) scale(1);
            opacity: 0.5;
        }
    }
}
</style>
