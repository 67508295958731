<template>
    <v-navigation-drawer
        v-model="$store.state.navigationDrawer"
        fixed
        temporary
        color="red darken-4"
    >
        <v-list nav dense>
            <v-list-item-group
                v-model="group"
                active-class="red--text orange lighten-1"
            >
                <v-list-item to="/">
                    <v-list-item-icon>
                        <v-icon color="yellow lighten-3">mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title
                        class="text-uppercase yellow--text text--lighten-3"
                        >inicio</v-list-item-title
                    >
                </v-list-item>

                <v-list-item to="/nosotras">
                    <v-list-item-icon>
                        <v-icon color="yellow lighten-3"
                            >mdi-account-group</v-icon
                        >
                    </v-list-item-icon>
                    <v-list-item-title
                        class="text-uppercase yellow--text text--lighten-3"
                        >nosotras</v-list-item-title
                    >
                </v-list-item>

                <v-list-item to="/talleres">
                    <v-list-item-icon>
                        <v-icon color="yellow lighten-3"
                            >mdi-book-education</v-icon
                        >
                    </v-list-item-icon>
                    <v-list-item-title
                        class="text-uppercase yellow--text text--lighten-3"
                        >summer school</v-list-item-title
                    >
                </v-list-item>

                <v-list-item to="/media">
                    <v-list-item-icon>
                        <v-icon color="yellow lighten-3"
                            >mdi-folder-multiple-image</v-icon
                        >
                    </v-list-item-icon>
                    <v-list-item-title
                        class="text-uppercase yellow--text text--lighten-3"
                        >galeria</v-list-item-title
                    >
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    data() {
        return {
            group: null,
        };
    },
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}
</style>
