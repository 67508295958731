<template>
    <GmapMap
        :center="{ lat: -33.43161017485904, lng: -70.59887674586257 }"
        :zoom="17"
        map-type-id="terrain"
        class="google-map-style"
    >
        <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center = m.position"
        />
    </GmapMap>
</template>

<script>
// -33.43161017485904, -70.59887674586257
export default {
    data() {
        return {
            markers: [
                {
                    position: {
                        lat: -33.43161017485904,
                        lng: -70.59887674586257,
                    },
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.google-map-style {
    width: 100%;
    height: 400px;
}
</style>
