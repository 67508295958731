var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":_vm.color,"elevation":"0","tile":""}},[_c('v-card-title',[_c('span',{staticClass:"ml-2 font-weight-bold",class:_vm.heading},[_vm._v(" Formulario de Contacto ")])]),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('small',{staticClass:"ml-2",class:_vm.alert},[_vm._v("* campos marcados con un asterisco, son obligatorios")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dark":_vm.theme,"label":"Su Nombre*","required":"","hint":"Nombre/s","rules":_vm.nameRules},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dark":_vm.theme,"label":"Su Apellido*","required":"","hint":"Apellido/s","rules":_vm.lastnameRules},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dark":_vm.theme,"label":"Email*","required":"","hint":"ejemplo: cuentausuario@dominio.ext","rules":_vm.emailRules},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dark":_vm.theme,"label":"Telefono*","required":"","hint":"ejemplo: 5691231234","rules":_vm.phoneRules},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"dark":_vm.theme,"items":[
                                'Starlight (sala cuna mayor)',
                                'Sunshine (medio menor)',
                                'Rainbow (transicion)' ],"label":"Nivel de Interes","attach":"","chips":"","multiple":""},model:{value:(_vm.level),callback:function ($$v) {_vm.level=$$v},expression:"level"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"dark":_vm.theme,"items":[
                                'Ciencias',
                                'Cocina',
                                'Reciclado',
                                'ArteManualidades',
                                _vm.taller ],"label":"Talleres de Interes","attach":"","chips":"","multiple":"","value":_vm.course},model:{value:(_vm.course),callback:function ($$v) {_vm.course=$$v},expression:"course"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"dark":_vm.theme,"label":"Mensaje","hint":"Haganos saber el motivo de su consulta","auto-grow":"","rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)],1)],1)],1),_c('v-card-text',{staticClass:"text-center"},[(_vm.$store.state.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.loader}})],1):_vm._e(),(_vm.$store.state.alert.show)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"prominent":"","border":"left","color":_vm.$store.state.alert.style,"type":_vm.$store.state.alert.type},domProps:{"textContent":_vm._s(_vm.$store.state.alert.message)}})],1):_vm._e()],1),_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.google_captcha_site_key,"loadRecaptchaScript":true},on:{"verify":_vm.onVerify}}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 mr-4 px-4",attrs:{"color":"blue darken-1","large":"","dark":""},on:{"click":_vm.validate}},[_vm._v(" Enviar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }