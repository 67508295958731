<template>
    <section>
        <v-container class="mt-10">
            <v-row align="end" justify="center">
                <v-col cols="12" class="mb-8">
                    <h1
                        id="levels-title"
                        class="
                            text-h3 text-center text-uppercase
                            font-weight-bold
                            levels-title
                        "
                    >
                        Nuestros Niveles
                    </h1>
                    <div class="d-flex justify-center my-6">
                        <v-btn
                            id="levels-subtitle"
                            outlined
                            color="white"
                            x-large
                            @click="verJornadas = !verJornadas"
                        >
                            <v-icon class="white--text"
                                >mdi-chevron-double-right</v-icon
                            >
                            {{
                                !verJornadas
                                    ? 'ver jornadas'
                                    : 'esconder jornadas'
                            }}
                            <v-icon class="white--text"
                                >mdi-chevron-double-left</v-icon
                            >
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <!-- Jornadas -->
        <horario-jornadas v-model="verJornadas" v-if="verJornadas" />
        <!-- Jornadas -->
        <v-container class="mt-10 mb-16">
            <v-row align="start" justify="center">
                <v-col cols="12" lg="4">
                    <v-container
                        fluid
                        :class="{ 'px-16': $vuetify.breakpoint.xl }"
                    >
                        <v-row align="start" justify="center">
                            <v-col
                                cols="10"
                                class="starlight-level-margin-top"
                                id="starlight"
                            >
                                <v-card
                                    class="pt-2"
                                    :class="{ 'mx-3': $vuetify.breakpoint.xl }"
                                    color="light-blue darken-4"
                                    rounded="xl"
                                    elevation="4"
                                >
                                    <v-card-title
                                        class="
                                            text-h3
                                            font-weight-bold
                                            white--text
                                            text-shadow-red
                                            justify-center
                                        "
                                    >
                                        Starlight
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="
                                            text-h5 text-center
                                            white--text
                                            pt-2
                                        "
                                    >
                                        (sala cuna mayor)
                                    </v-card-subtitle>
                                    <v-card-text
                                        class="
                                            text-h4
                                            font-weight-bold
                                            text-center
                                            official-yellow
                                            light-blue--text
                                            text--darken-4 text-shadow-orange
                                            pt-3
                                        "
                                    >
                                        2 a&ntilde;os
                                    </v-card-text>
                                </v-card>

                                <floating-island-kids-customs-one
                                    class="mt-10"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-container
                        fluid
                        :class="{ 'px-8': $vuetify.breakpoint.xl }"
                    >
                        <v-row align="start" justify="center">
                            <v-col
                                cols="10"
                                class="sunshine-level-margin-top"
                                id="sunshine"
                            >
                                <v-card
                                    class="pt-2"
                                    :class="{ 'mx-8': $vuetify.breakpoint.xl }"
                                    color="light-blue darken-4"
                                    rounded="xl"
                                    elevation="4"
                                >
                                    <v-card-title
                                        class="
                                            text-h3
                                            font-weight-bold
                                            white--text
                                            text-shadow-red
                                            justify-center
                                        "
                                    >
                                        Sunshine
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="
                                            text-h5 text-center
                                            white--text
                                            pt-2
                                        "
                                    >
                                        (medio menor)
                                    </v-card-subtitle>
                                    <v-card-text
                                        class="
                                            text-h4
                                            font-weight-bold
                                            text-center
                                            official-orange
                                            white--text
                                            text--darken-4 text-shadow-red
                                            pt-3
                                        "
                                    >
                                        3 a&ntilde;os
                                    </v-card-text>
                                </v-card>

                                <floating-island-kids-customs-two
                                    class="mt-10"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
                <v-col cols="12" lg="4">
                    <v-container
                        fluid
                        :class="{ 'px-4': $vuetify.breakpoint.xl }"
                    >
                        <v-row align="end" justify="center">
                            <v-col
                                cols="10"
                                class="rainbow-level-margin-top"
                                id="rainbow"
                            >
                                <v-card
                                    class="pt-2"
                                    :class="{ 'mx-12': $vuetify.breakpoint.xl }"
                                    color="light-blue darken-4"
                                    rounded="xl"
                                    elevation="4"
                                >
                                    <v-card-title
                                        class="
                                            text-h3
                                            font-weight-bold
                                            white--text
                                            text-shadow-red
                                            justify-center
                                        "
                                    >
                                        Rainbow
                                    </v-card-title>
                                    <v-card-subtitle
                                        class="
                                            text-h5 text-center
                                            white--text
                                            pt-2
                                        "
                                    >
                                        (transici&oacute;n)
                                    </v-card-subtitle>
                                    <v-card-text
                                        class="
                                            text-h4
                                            font-weight-bold
                                            text-center
                                            official-yellow
                                            light-blue--text
                                            text--darken-4 text-shadow-orange
                                            pt-3
                                        "
                                    >
                                        4-5 a&ntilde;os
                                    </v-card-text>
                                </v-card>

                                <floating-island-kids-customs-three
                                    class="mt-10"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import FloatingIslandKidsCustomsOne from '../components/svg/FloatingIslandKidsCustomsOne.vue';
import FloatingIslandKidsCustomsTwo from '../components/svg/FloatingIslandKidsCustomsTwo.vue';
import FloatingIslandKidsCustomsThree from '../components/svg/FloatingIslandKidsCustomsThree.vue';
import HorarioJornadas from './HorarioJornadas.vue';

export default {
    components: {
        FloatingIslandKidsCustomsOne,
        FloatingIslandKidsCustomsTwo,
        FloatingIslandKidsCustomsThree,
        HorarioJornadas,
    },
    data: () => ({
        verJornadas: false,
    }),
};
</script>

<style lang="scss" scoped>
$official-red: #c12a30;
$official-orange: #f4ad28;
$official-yellow: #f7db39;

.official-red {
    background-color: $official-red;
}
.official-red--text {
    color: $official-red;
}
.official-orange {
    background-color: $official-orange;
}
.official-orange--text {
    color: $official-orange;
}
.official-yellow {
    background-color: $official-yellow;
}
.official-yellow--text {
    color: $official-yellow;
}

#levels-title,
#levels-subtitle {
    animation-name: titles;
    animation-duration: 6s;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
}
.levels-title {
    text-shadow: 0px 4px 3px $official-red;
}
@keyframes titles {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(10px);
    }
}

.text-shadow-red {
    text-shadow: 0px 4px 3px $official-red;
}
.text-shadow-orange {
    text-shadow: 0px 4px 3px $official-orange;
}
.text-shadow-yellow {
    text-shadow: 0px 4px 3px $official-yellow;
}

.starlight-level-margin-top {
    margin-top: 0px;
}
.sunshine-level-margin-top {
    margin-top: 75px;
}
.rainbow-level-margin-top {
    margin-top: 150px;
}

#starlight,
#sunshine,
#rainbow {
    animation-name: island-kids;
    animation-play-state: running;
    animation-direction: alternate-reverse;
    animation-iteration-count: infinite;
    animation-duration: 5s;
}

@keyframes island-kids {
    from {
        transform: translateY(-10px);
    }
    to {
        transform: translateY(10px);
    }
}
</style>
