import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import * as GmapVue from 'gmap-vue';

Vue.config.productionTip = false;

Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyAPrfDfL3CCTkLDhDuzcbZmxk-bcd8eu14',
        libraries: 'places',
    },
    installComponents: true,
});

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount('#app');
